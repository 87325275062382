import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery } from '../styles';

const StyledDonationList = styled.section``;

const StyledInner = styled.div`
  display: grid;
  grid-gap: 30px 20px;

  ${minBreakpointQuery.small`
    grid-row-gap: 40px;
  `}

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${minBreakpointQuery.medium`
    grid-row-gap: 50px;
  `}

  ${minBreakpointQuery.large`
    grid-row-gap: 60px;
  `}
`;

const DonationList = ({ alt, children, ...props }) => (
  <StyledDonationList alt={alt} {...props}>
    <StyledInner>{children}</StyledInner>
  </StyledDonationList>
);

export default DonationList;
