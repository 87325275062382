import React from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  sectionPaddings,
  standardColours,
} from '../styles';
import { Container, SectionHeader } from '../components/ui';
import DonationList from '../components/DonationList';
import DonationCard from '../components/DonationCard';
import grassBg from '../images/grass-bg.svg';

const StyledDonationListing = styled.section`
  ${({ patternBackground }) => {
    if (patternBackground) {
      return css`
        padding-bottom: 40px;
        background: url('${grassBg}') no-repeat calc(100% + 50px)
          calc(100% + 50px) / 200px ${standardColours.lightGrey};

        ${minBreakpointQuery.small`
          padding-bottom: 50px;
        `}

        ${minBreakpointQuery.medium`
          padding-bottom: 60px;
        `}

        ${minBreakpointQuery.large`
          padding-bottom: 70px;
        `}

        ${minBreakpointQuery.xxlarge`
          padding-bottom: 80px;
        `}
      `;
    } else {
      return css`
        ${sectionPaddings()};
        background: ${standardColours.lightGrey};
      `;
    }
  }}
`;

const StyledInner = styled.div`
  display: grid;
  gap: 40px;

  ${minBreakpointQuery.small`
    gap: 50px;
  `}

  ${minBreakpointQuery.medium`
    gap: 60px;
  `}

  ${minBreakpointQuery.large`
    gap: 70px;
  `}

  ${minBreakpointQuery.xlarge`
    gap: 80px;
  `}
`;

const StyledDonations = styled.div``;

const DonationListing = ({
  overline,
  heading,
  donationItems,
  donationType,
  isDonatePage,
}) => (
  <StyledDonationListing
    patternBackground={isDonatePage && !donationType}
    background={isDonatePage && donationType}
  >
    <Container>
      <StyledInner>
        <StyledDonations>
          <SectionHeader overline={overline} heading={heading} />
          <DonationList>
            {donationItems.map(donationItem => (
              <DonationCard
                key={donationItem.id}
                data={donationItem}
                single={donationType}
              />
            ))}
          </DonationList>
        </StyledDonations>
      </StyledInner>
    </Container>
  </StyledDonationListing>
);

export default DonationListing;
